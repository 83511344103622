import { Checkbox, TableRow, TableBody, TableCell } from "@mui/material";
import { useParams, Link } from "react-router-dom";
//-----------------------------------------------------------------------//
//-----------------------------------------------------------------------//
const AppTableBody = ({
  rows,
  columns,
  page,
  rowsPerPage,
  selected,
  handleCheckbox,
  handleRowClick,
  to,
  //The following  prop is used if books are displayed by category,serie,author to enable ordering feature
  handleOrderChange,
}) => {
  const params = useParams();
  const renderCell = (column, row, index) => {
    if (column.id === "orderInCategory" && params.type === "categories")
      return column.component(row, index, params.id, handleOrderChange);
    if (column.component) return column.component(row);

    const value = row[column.id];
    return value;
  };
  //-----------------------------------------------------------------------//
  //-----------------------------------------------------------------------//
  return (
    <TableBody>
      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
        const selectedItem = selected ? selected.indexOf(row._id) !== -1 : false;
        return (
          <TableRow
            component={to ? Link : TableRow}
            to={
              to === "/admin/"
                ? `${to}${row.isSerie ? "serieses/edit?id=" : "books/edit?id="}${row._id}`
                : `${to}${row._id}`
            }
            hover
            role="checkbox"
            tabIndex={-1}
            key={row._id}
            onClick={() => {
              if (!to) handleRowClick(row);
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            {columns.map((column) => {
              if (
                (column.id === "orderInCategoryChange" || column.id === "orderInCategory") &&
                params.type !== "categories"
              ) {
                return null;
              }
              return (
                <TableCell key={column.id} align={column.align} dir="ltr">
                  {renderCell(column, row, index)}
                </TableCell>
              );
            })}
            {handleCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox checked={selectedItem} onChange={(event) => handleCheckbox(event, row._id)} />
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default AppTableBody;
