import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// import { createTheme } from "@mui/material/styles";

import router from "./app/router";
import ThemeProvider from "./theme";
import "./assets/css/app.css";

// const theme = createTheme({
//     typography: {
//         fontFamily: "Markazi Text",
//     },
//     status: {
//         danger: "#e53e3e",
//     },
//     palette: {
//         primary: {
//             main: "#0971f1",
//             darker: "#053e85",
//         },
//         neutral: {
//             main: "#64748B",
//             contrastText: "#fff",
//         },
//         white: {
//             main: "#fff",
//             darker: "#f5f5f5",
//         },
//         dark: {
//             main: "#111",
//             contrastText: "#f1f1f1",
//         },
//     },
// });

function App() {
    return (
        <HelmetProvider>
            <ThemeProvider>
                <RouterProvider router={router} />
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
