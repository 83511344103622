import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../app/features/userSlice";

const ProtectedRoute = () => {
  const user = useSelector(selectUser);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <Suspense fallback={<>...</>}>{<Outlet></Outlet>}</Suspense>;
};

export default ProtectedRoute;
