import { filter } from "lodash";
import PropTypes from "prop-types";

function descendingComparator(a, b, orderBy) {
  if (orderBy === "book.title") {
    if (b["book"]["title"] < a["book"]["title"]) {
      return -1;
    }
    if (b["book"]["title"] > a["book"]["title"]) {
      return 1;
    }
  }

  //----------------------------------------------------//
  //------------This is for User logs table-------------//
  if (orderBy === "user.logs.book.audioFile.duration") {
    if (b["book"]["audioFile"]["duration"] < a["book"]["audioFile"]["duration"]) {
      return -1;
    }
    if (b["book"]["audioFile"]["duration"] > a["book"]["audioFile"]["duration"]) {
      return 1;
    }
  }
  if (orderBy === "user.logs.book.progress") {
    const progressA = a["position"] / a["book"]["audioFile"]["duration"];
    const progressB = b["position"] / b["book"]["audioFile"]["duration"];
    if (progressB < progressA) {
      return -1;
    }
    if (progressB > progressA) {
      return 1;
    }
  }
  //----------------------------------------------------//
  //---------This is for Admin user logs table----------//
  if (orderBy === "admin.logs.book.title") {
    if (b["bookId"]["title"] < a["bookId"]["title"]) {
      return -1;
    }
    if (b["bookId"]["title"] > a["bookId"]["title"]) {
      return 1;
    }
  }

  if (orderBy === "admin.logs.book.audioFile.duration") {
    if (b["bookId"]["audioFile"]["duration"] < a["bookId"]["audioFile"]["duration"]) {
      return -1;
    }
    if (b["bookId"]["audioFile"]["duration"] > a["bookId"]["audioFile"]["duration"]) {
      return 1;
    }
  }
  if (orderBy === "admin.logs.book.progress") {
    const progressA = a["position"] / a["bookId"]["audioFile"]["duration"];
    const progressB = b["position"] / b["bookId"]["audioFile"]["duration"];
    if (progressB < progressA) {
      return -1;
    }
    if (progressB > progressA) {
      return 1;
    }
  }
  //----------------------------------------------//
  //---------This is for comments table-----------//
  if (orderBy === "comments.author.name") {
    if (b["author"]["name"] < a["author"]["name"]) {
      return -1;
    }
    if (b["author"]["name"] > a["author"]["name"]) {
      return 1;
    }
  }
  if (orderBy === "comments.author.email") {
    if (b["author"]["email"] < a["author"]["email"]) {
      return -1;
    }
    if (b["author"]["email"] > a["author"]["email"]) {
      return 1;
    }
  }
  if (orderBy === "comments.book.title") {
    if (b["bookId"]["title"] < a["bookId"]["title"]) {
      return -1;
    }
    if (b["bookId"]["title"] > a["bookId"]["title"]) {
      return 1;
    }
  }
  if (orderBy === "likes") {
    if (b["likes"].length < a["likes"].length) {
      return -1;
    }
    if (b["likes"].length > a["likes"].length) {
      return 1;
    }
  }
  if (orderBy === "disLikes") {
    if (b["dislikes"].length < a["dislikes"].length) {
      return -1;
    }
    if (b["dislikes"].length > a["dislikes"].length) {
      return 1;
    }
  }
  if (orderBy === "reports") {
    if (b["reports"].length < a["reports"].length) {
      return -1;
    }
    if (b["reports"].length > a["reports"].length) {
      return 1;
    }
  }
  if (orderBy === "replies") {
    if (b["replies"].length < a["replies"].length) {
      return -1;
    }
    if (b["replies"].length > a["replies"].length) {
      return 1;
    }
  }
  //----------------------------------------------//
  //----------This is for users table-------------//
  if (orderBy === "activeUntil") {
    if (new Date(b["activeUntil"]).getTime() < new Date(a["activeUntil"]).getTime()) {
      return -1;
    }
    if (new Date(b["activeUntil"]).getTime() > new Date(a["activeUntil"]).getTime()) {
      return 1;
    }
  }
  //----------------------------------------------//
  //----------This is for books table-------------//
  if (orderBy === "book.author.name") {
    if (b["author"]["name"] < a["author"]["name"]) {
      return -1;
    }
    if (b["author"]["name"] > a["author"]["name"]) {
      return 1;
    }
  }

  if (orderBy === "book.audioFile.duration") {
    if (b["audioFile"]["duration"] < a["audioFile"]["duration"]) {
      return -1;
    }
    if (b["audioFile"]["duration"] > a["audioFile"]["duration"]) {
      return 1;
    }
  }

  if (orderBy === "serie") {
    if (!b.serie) b.serie = { title: "" };
    if (!a.serie) a.serie = { title: "" };
    if (b["serie"]["title"] < a["serie"]["title"]) {
      return -1;
    }
    if (b["serie"]["title"] > a["serie"]["title"]) {
      return 1;
    }
  }
  if (orderBy === "scheduled") {
    if (new Date(b["scheduled"]).getTime() < new Date(a["scheduled"]).getTime()) {
      return -1;
    }
    if (new Date(b["scheduled"]).getTime() > new Date(a["scheduled"]).getTime()) {
      return 1;
    }
  }
  //---------------------------------------------//
  //--------This is for general columns----------//
  if (orderBy === "createdAt") {
    if (new Date(b["createdAt"]).getTime() < new Date(a["createdAt"]).getTime()) {
      return -1;
    }
    if (new Date(b["createdAt"]).getTime() > new Date(a["createdAt"]).getTime()) {
      return 1;
    }
  }

  if (orderBy === "updatedAt") {
    if (new Date(b["updatedAt"]).getTime() < new Date(a["updatedAt"]).getTime()) {
      return -1;
    }
    if (new Date(b["updatedAt"]).getTime() > new Date(a["updatedAt"]).getTime()) {
      return 1;
    }
  }

  //-------------------------------------//
  //--------This is generalized----------//
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

applySortFilter.propTypes = {
  array: PropTypes.array.isRequired,
  comparator: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  queryBy: PropTypes.array,
};

function applySortFilter(array, comparator, query, queryBy = []) {
  let sortedAndFiltered = [...array];
  //---------------------------------------------------------//
  //---------------------------------------------------------//
  sortedAndFiltered.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return 0;
  });
  //---------------------------------------------------------//
  //---------------------------------------------------------//
  //the 1st following if condition is added to avoid further filteration for comments, transactions, users tables, because filtering is done @backend
  if (!queryBy.includes("avoidFurtherFilteration")) {
    if (query) {
      return filter(sortedAndFiltered, (item) => {
        for (let i = 0; i < queryBy.length; i++) {
          let key = queryBy[i];
          if (key.indexOf(".") !== -1) {
            let keys = key.split(".");
            let value = item;
            for (let j = 0; j < keys.length; j++) {
              value = value[keys[j]];
            }
            if (value?.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
              return true;
            } else return false;
          } else {
            if (item[key]?.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
              return true;
            } else return false;
          }
        }
        return false;
      });
    }
  }
  return sortedAndFiltered;
}

export { descendingComparator, getComparator, applySortFilter };
