import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import footerLogo from "../assets/images/footer/footer-logo.png";
import footerApple from "../assets/images/footer/footer-apple.png";
import footerGoogle from "../assets/images/footer/footer-google.png";
import footerHuwaei from "../assets/images/footer/footer-huwaei.png";
import footerFacebook from "../assets/images/footer/footer-fb.png";
import footerX from "../assets/images/footer/footer-x.png";
import footerYoutube from "../assets/images/footer/footer-youtube.png";
import footerInsta from "../assets/images/footer/footer-insta.png";
const Footer = () => {
  return (
    <footer className="w-full" style={{ marginTop: "auto" }}>
      <Box className="flex flex-row justify-between max-lg:flex-col items-center bg-[url('assets/images/footer/footer-bg.jpg')] bg-cover bg-center">
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        <Box className="w-4/5 p-4 flex flex-row items-center justify-around max-lg:w-full max-lg:flex-col max-lg:items-center ">
          {/* ------------- */}
          {/* ------------- */}
          <Box className="w-1/4 max-lg:w-full max-lg:flex max-lg:justify-center">
            <img src={footerLogo} alt="audiohat-logo"></img>
          </Box>
          {/* ------------- */}
          {/* ------------- */}
          <Box className="flex flex-col w-3/4 max-lg:w-full max-lg:items-center">
            {/* ---- */}
            {/* ---- */}
            <Typography className="text-white mt-3 mb-3" variant="h6">
              استمع إلى مئات الروايات و الكتب بنكهة درامية مميزة
            </Typography>
            {/* ---- */}
            {/* ---- */}
            <Box className="flex flex-row max-lg:flex-col items-center mt-3 mb-3">
              <img
                className="w-[180px] m-2 cursor-pointer"
                onClick={() => {
                  window.open("https://apple.co/3grgA8W");
                }}
                src={footerApple}
                alt="IOS app link"
              ></img>
              <img
                className="w-[180px] m-2 cursor-pointer"
                onClick={() => {
                  window.open("https://bit.ly/3bkhhjd");
                }}
                src={footerGoogle}
                alt="Android app link"
              ></img>
              <img
                className="w-[180px] m-2 cursor-pointer"
                onClick={() => {
                  window.open("https://bit.ly/3k3rpls");
                }}
                src={footerHuwaei}
                alt="App gallery link"
              ></img>
            </Box>
            {/* ---- */}
            {/* ---- */}
            <Box className="flex flex-row mt-3 mb-3">
              <img
                src={footerFacebook}
                className="m-1 cursor-pointer"
                onClick={() => {
                  window.open("https://www.facebook.com/Audiohat");
                }}
                alt="facebook link"
              ></img>

              <img
                src={footerYoutube}
                className="m-1 cursor-pointer"
                onClick={() => {
                  window.open("https://www.youtube.com/channel/UCMyAMbDuTCUkG8YXwokux-g");
                }}
                alt="youtube link"
              ></img>

              <img
                src={footerX}
                className="m-1 cursor-pointer"
                onClick={() => {
                  window.open("https://twitter.com/Audiohat_");
                }}
                alt="X link"
              ></img>

              <img
                src={footerInsta}
                className="m-1 cursor-pointer"
                onClick={() => {
                  window.open("https://www.instagram.com/audiohatofficial/");
                }}
                alt="instagram link"
              ></img>
            </Box>
            {/* ---- */}
            {/* ---- */}
          </Box>
        </Box>
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        <Box className="w-1/5 max-lg:w-full max-lg:text-center">
          <Typography className="text-orange-600 text-bold" variant="h4">
            روابط أخرى
          </Typography>
          <Box className="flex flex-col justify-center text-white">
            <Link className="block text-xl" href="/">
              الرئيسية
            </Link>
            <a
              className="block text-xl"
              href="https://wp.audiohatdar.com/privacy-policy/?hide=true"
            >
              سياسة الخصوصية
            </a>
            <a
              className="block text-xl"
              href="https://wp.audiohatdar.com/terms_and_conditions/?hide=true"
            >
              الشروط و الأحكام
            </a>
            <a className="block text-xl" href="https://wp.audiohatdar.com/contact_us/?hide=true">
              اتصل بنا
            </a>
          </Box>
        </Box>
      </Box>
      {/* -------------------------------------------------------- */}
      {/* -------------------------------------------------------- */}
      <Box className="text-center text-white bg-zinc-950">
        {`Copyright ${new Date().getFullYear()} © Audiohatdar`}
      </Box>
    </footer>
  );
};

export default Footer;
