import { Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { Nav, Footer, ScrollToTop, CircleBackButton } from "../../components";
import { UserUtils } from "../../utils";
import "react-toastify/dist/ReactToastify.css";
//-------------------------------------------------------------------//
//-------------------------------------------------------------------//
const RootLayout = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { toastMessage, toastType, toastId } = location.state || {};
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  if (toastMessage) {
    toast[toastType](toastMessage, {
      toastId: toastId,
      position: toast.POSITION.TOP_CENTER,
      onClose: () => window.history.replaceState(null, "", location.pathname),
    });
  }

  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  UserUtils.getGeolocation();
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  return (
    <>
      <Helmet>
        <title>{t("helmet.pageTitles.root")}</title>
      </Helmet>
      {/* ------------------------------------------------------------------*/}
      {/* ------------------------------------------------------------------*/}
      <Nav />
      {/* -----------------------------------------*/}
      {/* -----------------------------------------*/}
      <main className="bg-black" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        <ScrollToTop />
        <Outlet />
      </main>
      {/* -----------------------------------------*/}
      {/* -----------------------------------------*/}
      <Footer />
      {/* -----------------------------------------*/}
      {/* -----------------------------------------*/}
      {/* ------------------------------------------------------------------*/}
      {/* ------------------------------------------------------------------*/}
      <ToastContainer rtl />
      {/* ------------------------------------------------------------------*/}
      {/* ------------------------------------------------------------------*/}
      <CircleBackButton />
    </>
  );
};

export default RootLayout;
