import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Iconify from "../iconify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: 32,
  marginTop: 8,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function FormModalMultiSelectTable({ rows, selected, open, title, handleClose, handleSelect, width = 400 }) {
  const { t } = useTranslation();
  const [filteredRows, setFilteredRows] = useState(rows);
  //const selectedIds = selected.map((row) => row._id);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setFilteredRows(rows);
        handleClose();
      }}
      scroll="paper"
      sx={{
        "& .MuiDialog-paper": {
          width: width,
          height: 400,
          maxHeight: "calc(100% - 64px)",
          m: 0,
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle>
        <div>{title}</div>
        <StyledSearch
          onChange={(e) => {
            setFilteredRows(rows.filter((row) => row.title.toLowerCase().includes(e.target.value.toLowerCase())));
          }}
          placeholder={t("search")}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              {filteredRows.map((row) => {
                const disabled = false; //row.serie !== null;
                return (
                  <TableRow
                    key={row._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: disabled ? "not-allowed" : "pointer",
                      opacity: disabled ? 0.5 : 1,
                    }}
                  >
                    <TableCell
                      onClick={() => {
                        if (!disabled) {
                          handleSelect(row);
                        }
                      }}
                    >
                      {selected.some((selectedRow) => selectedRow._id === row._id) ? checkedIcon : icon}
                    </TableCell>
                    <TableCell>
                      <Typography className="block" variant="caption">
                        {row.title}
                      </Typography>

                      <Typography className="block" variant="caption">
                        {row.subtitle}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFilteredRows(rows);
            handleClose();
          }}
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
