import { useState, forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { Cart } from "../../utils";
import { Loading, Card } from "../index";
import { useSelector } from "react-redux";
import { selectUser } from "../../app/features/userSlice";
import { axios, requests } from "../../api";
import { useNavigate } from "react-router-dom";
import google from "../../assets/images/home/google.png";
import apple from "../../assets/images/home/apple.png";
//------------------------------------------------------------------------------//
//------------------------------------------------------------------------------//
//------------------------------------------------------------------------------//
//------------------------------------------------------------------------------//
/* AppAlertDialog */
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//------------------------------------------------------------------------------//
//------------------------------------------------------------------------------//
const AppAlertDialog = ({ open, title, handleClose, message }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------//
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="AppAlertDialog"
      sx={{
        "& .MuiDialog-paper": { width: "100%", maxWidth: "400px", padding: 2 },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText id="AppAlertDialog">{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions className="flex flex-row justify-between items-center">
        <Button
          variant="outlined"
          onClick={() => navigate("/login", { state: { from: window.location.pathname } })}
        >
          {t("user.login")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate("/register", { state: { from: window.location.pathname } })}
        >
          {t("user.register")}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
/* End AppAlertDialog */
//------------------------------------------------------------------------------//
//------------------------------------------------------------------------------//
const Plans = () => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [appAlertDialog, setAppAlertDialog] = useState(false);
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const cart = Cart.get();
  //------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------//
  useEffect(() => {
    async function getPlans() {
      try {
        setLoading(true);
        const { data } = await axios.get(requests.fetchPlans);
        if (data && data.plans) {
          setPlans(data.plans);
        } else {
          setPlans([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    getPlans();
  }, []);
  //------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------//
  if (loading) {
    return <Loading title={t("loading")} />;
  }
  //------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------//
  return (
    <>
      {/*--------------------------------------------------------*/}
      {/*--------------------------------------------------------*/}
      <Box className="p-16 flex flex-col items-center bg-neutral-700 h-fit" id="plansSection">
        {/*-------------------------------------------*/}
        {/*-------------------------------------------*/}
        <Typography variant="h2" className="text-center text-white underline-offset-8 mb-2">
          {t("plans.Subscriptions")}
        </Typography>
        <span className="block text-xl text-slate-200 text-center mb-8">
          اشترك في الباقة الأنسب لك و تمتع بتجربة استماع مميزة لدراما إذاعية شيقة تأخذك إلي عوالمها
          المميزة بلا حدود
        </span>

        <span className="block text-lg text-rose-500 text-center mb-8">
          ملحوظة:"الاشتراك يتيح لك الاستماع إلى كامل الحلقات من خلال التطبيق لا الموقع"
        </span>
        {/*-------------------------------------------*/}
        {/*-------------------------------------------*/}
        <Box className="flex flex-row flex-wrap justify-around items-end mb-8">
          {plans.map((plan) => (
            <Card
              key={plan._id}
              plan={plan}
              showSubscribeButton={true}
              user={user}
              cart={cart}
              setAppAlertDialog={setAppAlertDialog}
            />
          ))}
        </Box>
        {/*-------------------------------------------*/}
        {/*-------------------------------------------*/}
        <Box className="rounded-xl bg-neutral-950 max-lg:text-center p-4 max-w-screen-xl">
          <span className="block font-black text-3xl text-white max-lg:text-xl max-md:text-lg">
            يمكنك أيضا الدفع عن طريق{" "}
            <img className="inline w-[40px] max-md:w-[30px]" src={google} alt="google play"></img>{" "}
            جوجل أو{" "}
            <img className="inline w-[40px] max-md:w-[30px]" src={apple} alt="apple store"></img>{" "}
            أبل من خلال التطبيق
          </span>
          <span className="text-slate-100 text-lg max-md:text-md">
            رسوم الإشتراك قد تختلف بناء على البلد أو ما يعادلها بالعملة المحلية. تطبق{" "}
            <a
              href="https://wp.audiohatdar.com/terms_and_conditions/"
              className="underline text-orange-500"
            >
              الشروط و الأحكام
            </a>
          </span>
        </Box>
      </Box>
      {/*--------------------------------------------------------*/}
      {/*--------------------------------------------------------*/}
      <AppAlertDialog
        open={appAlertDialog}
        title={t("plans.loginRequired")}
        handleClose={() => setAppAlertDialog(false)}
      />
    </>
  );
};
export default Plans;
