import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";

const BookSubtitle = ({ book }) => {
  return (
    <span className="text-white block text-sm">
      <SubdirectoryArrowLeftIcon fontSize="12" className="ml-2 inline" />
      {book.subtitle}
    </span>
  );
};

export default BookSubtitle;
