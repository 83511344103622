import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
export default function CircleBackButton() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const skip = ["/", "/login", "/register", "/checkout", "/verify"];

    if (skip.includes(window.location.pathname)) {
        return null;
    }

    return (
        <div className="audiohat-back-button">
            <Button
                aria-label="Back"
                onClick={() => navigate(-1)}
                sx={{
                    padding: "0.5rem",
                    backgroundColor: "rgba(0, 0, 0, 1.0) !important",
                    color: "white !important",
                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.7) !important",
                        color: "black !important",
                    },
                }}>
                <Typography variant="srOnly">{t("Back")}</Typography>
                <ArrowBackIosNewIcon />
            </Button>
        </div>
    );
}
