import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};
const SLATECOLOR = {
  0: "#FFFFFF",
  50: "#f8fafc",
  100: "#f1f5f9",
  200: "#e2e8f0",
  300: "#cbd5e1",
  400: "#94a3b8",
  500: "#64748b",
  600: "#475569",
  700: "#334155",
  800: "#1e293b",
  900: "#0f172a",
  950: "#020617",
};
const PRIMARY = {
  lighter: "#D1E9FC",
  light: "#76B0F1",
  main: "#2065D1",
  dark: "#103996",
  darker: "#061B64",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};
const DARK = {
  main: "#111",
  light: "#333",
  dark: "#000",
  contrastText: "#fff",
  lighter: "#D1E9FC",
  darker: "#061B64",
};

const CLOUDS = {
  main: "#715d4d",
  light: "#cfcfcf",
  dark: "#483b33",
  contrastText: "#fff",
  lighter: "#ececec",
  darker: "#392d27",
};

const SWIDISH = {
  main: "#aa5f37",
  light: "#e5c692",
  dark: "#c27d3e",
  contrastText: "#111",
  lighter: "#f8f1e4",
  darker: "#aa5f37",
};

const RED = {
  main: "#f44336",
  light: "#e57373",
  dark: "#d32f2f",
  contrastText: "#fff",
  lighter: "#ffebee",
  darker: "#b71c1c",
};

const SLATE = {
  main: "#64748b",
  light: "#e2e8f0",
  dark: "#334155",
  contrastText: "#f8fafc",
  lighter: "#f1f5f9",
  darker: "#1e293b",
};

const ORANGE = {
  main: "#E94F36",
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  dark: DARK,
  clouds: CLOUDS,
  swidish: SWIDISH,
  red: RED,
  slate: SLATE,
  slatecolor: SLATECOLOR,
  orange: ORANGE,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: SLATECOLOR[800],
    secondary: SLATECOLOR[600],
    disabled: SLATECOLOR[500],
  },
  button: {
    background: SLATECOLOR[800],
    color: SLATECOLOR[50],
  },
  background: {
    paper: "#fff",
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(SLATECOLOR[500], 0.8),
    disabledBackground: alpha(SLATECOLOR[500], 0.24),
    focus: alpha(SLATECOLOR[500], 1.0),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
