import personPlaceholder from "../assets/images/person-placeholder.jpg";
import imagePlaceholder from "../assets/images/imagePlaceholder.svg";
import mediaPlaceholder from "../assets/images/mediaPlaceholder.svg";

class ImageUtils {
  static getMixedObjLocation(obj, type = "person") {
    if (!obj) {
      if (type === "poster") {
        return imagePlaceholder;
      }
      if (type === "media") {
        return mediaPlaceholder;
      }
      return personPlaceholder;
    }
    // if obj is a string, return it
    if (typeof obj === "string") {
      //return encodeURI(obj);
      return obj;
    }

    if (obj["location"] && obj["location"].length > 0) {
      return obj["location"];
    }

    if (obj["thumbnail"] && obj["thumbnail"].location && obj["thumbnail"].location.length > 0) {
      return obj["thumbnail"].location;
    }

    if (obj["icon"] && obj["icon"].location && obj["icon"].location.length > 0) {
      return obj["icon"].location;
    }

    if (obj["image"] && obj["image"].location && obj["image"].location.length > 0) {
      return obj["image"].location;
    }

    if (obj["image"] && obj["image"].length > 0) {
      return obj["image"];
    }

    if (obj["avatar"] && obj["avatar"].location && obj["avatar"].location.length > 0) {
      return obj["avatar"].location;
    }

    if (obj["avatar"] && typeof obj["avatar"] === "string" && obj["avatar"].length > 0) {
      return obj["avatar"];
    }
    if (type === "poster") {
      return imagePlaceholder;
    }
    if (type === "media") {
      return mediaPlaceholder;
    }

    return personPlaceholder;
  }
}

export default ImageUtils;
