import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import { Box, Paper } from "@mui/material";

import { ImageUtils } from "../../utils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AuthorDetails = ({ author }) => {
  const { t } = useTranslation();
  if (!author) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1 }} className="my-2">
      <Grid container spacing={2}>
        <Grid xs={4}>
          <Item>
            <img
              src={ImageUtils.getMixedObjLocation(author.avatar)}
              alt={author.name}
              className="w-full"
            />
          </Item>
        </Grid>
        <Grid xs={8}>
          <div className="mb-2">
            <h6 className="text-3xl text-white">{author.name}</h6>
            <p className="text-sm mt-3 text-white underline">{t("about_the_author")}</p>
            <p className="text-sm text-slate-300">{author.bio}</p>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthorDetails;
