import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import { IntegerUtil } from "../../utils";

const BookDuration = ({ book }) => {
  if (!book.soundFile?.duration && !book.freeAudioFile?.duration) return null;
  return (
    <span className="text-white text-sm">
      <AccessTimeTwoToneIcon fontSize="12" className="ml-2" />
      {IntegerUtil.durationHHMMSS(book.soundFile?.duration || book.freeAudioFile?.duration)}
    </span>
  );
};

export default BookDuration;
