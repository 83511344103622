import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import { motion } from "framer-motion";

import { ImageUtils } from "../utils/";
import AuthorNameWithIcon from "./Book/AuthorNameWithIcon";

/**
 * Grid component to be used in all pages except books page
 * @param {Array} items
 * @param {String} titleKey
 * @param {String} imageKey
 * @param {String} pathKey
 * @param {String | null} subitemsCountKey
 * @returns {JSX.Element}
 */
const GridComponent = ({
  items,
  titleKey,
  imageKey,
  pathKey,
  subitemsCountKey = null,
  imageClass,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // cols depend on screen size 2 for xs and 3 for sm and above

  const screenWidth = window.innerWidth;
  let cols = 4;
  if (screenWidth <= 414) {
    cols = 1;
  } else if (screenWidth > 414 && screenWidth <= 768) {
    cols = 2;
  } else if (screenWidth > 768 && screenWidth <= 1024) {
    cols = 3;
  }

  const handelOnClick = (item) => {
    navigate(`/${pathKey}/${item._id}/books`, { state: { items } });
  };

  const ItemImage = ({ item }) => {
    if (imageClass) {
      return (
        <div
          className="w-[120px] h-[120px] bg-[#f1f1f1] m-auto flex content-center items-center rounded-t-md hover:bg-slate-300 cursor-pointer mb-6"
          onClick={() => handelOnClick(item)}
        >
          <img
            src={ImageUtils.getMixedObjLocation(item[imageKey])}
            alt={item[titleKey]}
            className="m-auto"
          />
        </div>
      );
    }
    return (
      <img
        onClick={() => handelOnClick(item)}
        className="h-[250px] w-[100%] object-cover object-center hover:opacity-80 rounded-t-md cursor-pointer"
        src={ImageUtils.getMixedObjLocation(item[imageKey])}
        alt={item[titleKey]}
        loading="lazy"
      />
    );
  };

  return (
    <ImageList
      sx={{
        transform: "translateZ(0)",
      }}
      gap={5}
      cols={12}
    >
      {items.map((item, index) => {
        const subItemsCount = item[subitemsCountKey];
        const translatedCubItemsCount = t("books_count", { count: subItemsCount });

        return (
          <ImageListItem key={item._id} cols={12 / cols} rows={1}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: (index + 1) * 0.2, type: "spring" },
              }}
            >
              <ItemImage item={item} />
              <Box
                className="bg-neutral-600 text-white"
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  marginBottom: "1rem",
                  marginTop: "-0.1rem",
                  borderEndEndRadius: "0.5rem",
                  borderEndStartRadius: "0.5rem",
                  paddingY: "0.5rem",
                }}
              >
                <div className="underline cursor-pointer" onClick={() => handelOnClick(item)}>
                  {item[titleKey]}
                </div>
                <div>- {translatedCubItemsCount} -</div>
                <AuthorNameWithIcon book={item} />
              </Box>
            </motion.div>
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export default GridComponent;
