import { lazy } from "react";

const HomeScreen = lazy(() => import("./Home/HomeScreen"));
const LoginScreen = lazy(() => import("./Login/LoginScreen"));

const BookDetails = lazy(() => import("./Books/BookDetails"));

const CategoryBooks = lazy(() => import("./Categories/CategoryBooks"));
const CategoreyList = lazy(() => import("./Categories/CategoryList"));
const AuthorsList = lazy(() => import("./Authors/AuthorsLis"));
const AuthorBooks = lazy(() => import("./Authors/AuthorBooks"));

const SeriesBooks = lazy(() => import("./Series/SeriesBooks"));

//const NotificationsPage = lazy(() => import("./User/NotificationsPage"));
const AccountPage = lazy(() => import("./User/AccountPage"));
const ProfileScreen = lazy(() => import("./User/ProfileScreen"));
const ResetPasswordScreen = lazy(() => import("./Login/ResetPassword"));

const Checkout = lazy(() => import("./Checkout/Checkout"));
const VerifyPaytabs = lazy(() => import("./Checkout/VerifyPaytabs"));
const VerifyFawry = lazy(() => import("./Checkout/VerifyFawry"));
// const UserLogs = lazy(() => import("./User/UserLogs"));
// const UserFavorites = lazy(() => import("./User/UserFavorites"));
export {
  HomeScreen,
  LoginScreen,
  ProfileScreen,
  ResetPasswordScreen,
  BookDetails,
  CategoryBooks,
  CategoreyList,
  AuthorsList,
  AuthorBooks,
  SeriesBooks,
  Checkout,
  VerifyPaytabs,
  VerifyFawry,
  //UserFavorites,
  //UserLogs,
  //NotificationsPage,
  AccountPage,
};
