import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ImageUtils } from "../../utils";
import { motion } from "framer-motion";
import BookRatingView from "./BookRatingView";
import BookDuration from "./BookDuration";
import BookSubtitle from "./BookSubtitle";
import BookReleaseDate from "./BookReleaseDate";
import AuthorNameWithIcon from "./AuthorNameWithIcon";
import FreeBookBadge from "./FreeBookBadge";
//-------------------------------------------------------------------//
/**
 * JSX component to show books grid just in books page , it's different from GridComponent because it has more details
 * @param {Array} books
 * @returns JSX.Element
 */
//-------------------------------------------------------------------//
const BooksGrid = ({ books }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  //-------------------------------------------------------------------//
  const handelOnClick = (book) => {
    if (book.isSerie) {
      navigate(`/serieses/${book._id}/books`);
    } else {
      navigate(`/books/${book._id}`);
    }
  };
  //-------------------------------------------------------------------//
  function translateTitle(book) {
    const translatedTitle = book.isSerie
      ? t("serie", { title: book.title }) +
        " - " +
        t("books_count", { count: book.numberOfBooks }) +
        " - "
      : book.title;
    return translatedTitle;
  }
  //-------------------------------------------------------------------//
  return (
    <Box
      className="flex flex-row justify-center items-center flex-wrap pr-16 pl-16"
      sx={{
        transform: "translateZ(0)",
      }}
    >
      {books.map((book, index) => {
        return (
          <Box className="m-1" key={book._id}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: (index + 1) * 0.2, type: "spring" },
              }}
            >
              {/* ----------------- */}
              <Box className="h-[200px] w-[200px]  relative">
                <img
                  onClick={() => handelOnClick(book)}
                  className="h-full w-full object-cover object-center hover:opacity-80 cursor-pointer rounded-t-md"
                  src={ImageUtils.getMixedObjLocation(book.thumbnail)}
                  alt={book.title}
                  loading="lazy"
                />
                {book.isFree && <FreeBookBadge size="large" />}
              </Box>
              {/* ----------------- */}
              <Box className="absolute top-0 left-0 right-0 bg-neutral-750 text-right text-white">
                <BookRatingView book={book} />
              </Box>
              {/* ----------------- */}
              <Box className="w-[200px] min-h-[130px] bg-neutral-700 rounded-b-md text-white p-2 flex flex-col justify-center">
                <span className="block underline text-white text-sm font-black">
                  {translateTitle(book)}
                </span>
                {!book.isSerie && <BookSubtitle book={book} />}
                <AuthorNameWithIcon book={book} translate />
                <Box>
                  <BookDuration book={book} />
                  <BookReleaseDate book={book} />
                </Box>
              </Box>
              {/* ----------------- */}
            </motion.div>
          </Box>
        );
      })}
    </Box>
  );
};

export default BooksGrid;
