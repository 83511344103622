import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { login, selectUser } from "../app/features/userSlice";
import { ImageUtils } from "../utils";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Container,
  MenuItem,
  Menu,
  IconButton,
  Avatar,
  Tooltip,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
} from "@mui/material";
import { Person, AddShoppingCart, ArrowDropDown } from "@mui/icons-material";
import Iconify from "./iconify";
import MenuIcon from "@mui/icons-material/Menu";
import AudiohatLogo from "../assets/images/Audiohat_Logo.png";
import { categoriesLoader } from "../api";
//-------------------------------------------------------------------//
//-------------------------------------------------------------------//
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Nav() {
  const user = useSelector(selectUser);
  const [dialogOpen, setDialogOpen] = useState(false);
  const paths = [
    {
      title: "الملف الشخصي",
      path: "/user/profile",
      icon: <Iconify icon="icomoon-free:profile" />,
    },
    {
      title: "إدارة الحساب",
      path: "/user/account",
      icon: <Iconify icon="mdi:account-cog" />,
    },
    {
      title: "خروج",
      path: "/logout",
      icon: <Iconify icon="ep:turn-off" />,
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [loggedUser, setLoggedUser] = useState(null);
  const [endPoints, setEndPoints] = useState([]);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [categories, setCategories] = useState([]);
  const open = Boolean(anchorEl);
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleUserMenu = (item) => {
    setAnchorElUser(null);
    if (item.path === "/switchBack") {
      const adminUser = JSON.parse(localStorage.getItem("admin"));
      localStorage.removeItem("admin");
      dispatch(
        login({
          ...adminUser,
          token: adminUser.loggedInToken,
        })
      );
      navigate("/");
    } else if (item.path) {
      navigate(item.path);
    }
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleClose = () => {
    setAnchorEl(null);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  useEffect(() => {
    async function fetchData() {
      const categories = await categoriesLoader();
      if (categories.length > 0) {
        setCategories(categories);
      }
    }
    fetchData();
  }, []);
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  useEffect(() => {
    if (user) {
      setLoggedUser(user);
      if (user.isAdmin) {
        setEndPoints([
          ...paths,
          {
            title: "التحكم بالموقع",
            path: "/admin",
          },
        ]);
      } else if (user.temporary) {
        setEndPoints([
          ...paths,
          {
            title: "عودة إلي حسابي",
            path: "/switchBack",
            icon: <Iconify icon="ep:turn-off" />,
          },
        ]);
      } else {
        setEndPoints([...paths]);
      }
    } else {
      setEndPoints([]);
    }
    // eslint-disable-next-line
  }, [user]);
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  return (
    <AppBar position="fixed" className="bg-zinc-900">
      <Container maxWidth="2xl">
        <Toolbar disableGutters>
          {/* -----------------------PC-----------------------*/}
          {/* -----------------------PC-----------------------*/}
          {/* -----------------------PC-----------------------*/}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              mr: 10,
              mb: 0,
            }}
          >
            <MenuItem
              onClick={(e) => {
                navigate("/");
              }}
            >
              <img
                className="w-[40px] cursor-pointer"
                src={AudiohatLogo}
                alt="Audiohat"
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                navigate("/");
              }}
            >
              الرئيسية
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    document
                      .getElementById("ourWork")
                      .scrollIntoView({ behavior: "smooth" });
                  }, 2000);
                } else {
                  document
                    .getElementById("ourWork")
                    .scrollIntoView({ behaviour: "smooth" });
                }
              }}
            >
              أعمالنا
            </MenuItem>
            <MenuItem
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              التصنيفات
              <ArrowDropDown />
            </MenuItem>
            <Menu
              sx={{
                "& .MuiPaper-root": {
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#404040",
                  minWidth: "300px",
                  maxWidth: "500px",
                  maxHeight: "200px",
                  flexWrap: "wrap",
                },
              }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Paper className="bg-neutral-700">
                {categories.map((cat) => (
                  <MenuItem
                    className="text-white text-md hover:text-orange-500"
                    onClick={() => {
                      handleClose();
                      navigate(`/categories/${cat._id}/books`);
                    }}
                  >
                    {cat.label}
                  </MenuItem>
                ))}
              </Paper>
            </Menu>

            <MenuItem
              onClick={() => {
                window.open(
                  "https://audiohatdar.com/فريق-عمل-أوديوهات/?hide=true",
                  "_blank"
                );
              }}
            >
              فريق أوديوهات
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(
                  "https://audiohatdar.com/الأسئلة-المتكررة/?hide=true",
                  "_blank"
                );
              }}
            >
              الأسئلة المتكررة
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDialogOpen();
              }}
            >
              اتصل بنا
            </MenuItem>
          </Box>
          {/* --------------------Mobile----------------------*/}
          {/* --------------------Mobile----------------------*/}
          {/* --------------------Mobile----------------------*/}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/");
                }}
              >
                الرئيسية
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/categories");
                }}
              >
                التصنيفات
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  if (location.pathname !== "/") {
                    navigate("/");
                    setTimeout(() => {
                      document
                        .getElementById("ourWork")
                        .scrollIntoView({ behavior: "smooth" });
                    }, 2000);
                  } else {
                    document
                      .getElementById("ourWork")
                      .scrollIntoView({ behaviour: "smooth" });
                  }
                }}
              >
                أعمالنا
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(
                    "https://audiohatdar.com/فريق-عمل-أوديوهات/?hide=true",
                    "_blank"
                  );
                }}
              >
                فريق أوديوهات
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(
                    "https://audiohatdar.com/الأسئلة-المتكررة/?hide=true",
                    "_blank"
                  );
                }}
              >
                الأسئلة المتكررة
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(
                    "https://audiohatdar.com/contact_us/?hide=true",
                    "_blank"
                  );
                }}
              >
                اتصل بنا
              </MenuItem>
            </Menu>
          </Box>
          {/* --------------------Common----------------------*/}
          {/* --------------------Common----------------------*/}
          {/* --------------------Common----------------------*/}
          <Box>
            {/* subscribe button */}
            <Button
              className="rounded-full bg-orange-600 text-white ml-4"
              onClick={() => {
                handleCloseNavMenu();

                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    document.getElementById("plansSection").scrollIntoView();
                  }, 2000);
                } else {
                  document.getElementById("plansSection").scrollIntoView();
                }
              }}
            >
              <AddShoppingCart></AddShoppingCart> الإشتراك
            </Button>
            {/* Avatar */}
            {loggedUser && (
              <>
                <Tooltip title={t("user.profile")}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={loggedUser.name}
                      src={ImageUtils.getMixedObjLocation(loggedUser)}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleUserMenu}
                >
                  {endPoints.map((element, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => handleUserMenu(element)}
                        selected={window.location.pathname === element.path}
                      >
                        {element.title}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            )}
            {/* login button */}
            {!loggedUser && (
              <Button
                className="rounded-full text-white mr-2"
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/login");
                }}
              >
                <Person></Person> تسجيل دخول
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            backgroundColor: "#141414",
          },
        }}
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="text-white">{"اتصل بنا"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            className="text-white"
            id="alert-dialog-slide-description"
          >
            برجاء التواصل عل البريد الإلكتروني support@audiohatdar.com
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            className="text-white"
            variant="contained"
            color="info"
          >
            حسنا
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}
export default Nav;
