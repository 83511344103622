import { axios, requests } from "../../index";
const rows = [
  {
    id: "latestbooks",
    fetchUrl: `${requests.home}/latestbooks`,
  },
  {
    id: "isRecommended",
    fetchUrl: `${requests.home}/isRecommended`,
  },
  {
    id: "mostBooks",
    fetchUrl: `${requests.home}/mostBooks`,
  },
  {
    id: "myHistory",
    fetchUrl: `${requests.home}/myHistory`,
  },
];
const loadForHome = async (url) => {
  const { data } = await axios.get(url);
  if (data && data.items) {
    return data.items;
  }
  return [];
};

const homeLoader = async () => {
  const promises = rows.map(async (row) => {
    const items = await loadForHome(row.fetchUrl);
    if (row.id === "categories") {
      // loop over categories and fetch books for each category
      const promises = items.map(async (category) => {
        const books = await fetchHomeCategoriesBooks(category._id);
        return { ...category, books, isCats: true };
      });
      const categoriesWithBooks = await Promise.all(promises);
      return { id: row.id, items: categoriesWithBooks };
    }
    return { id: row.id, items };
  });
  const rowsWithItems = await Promise.all(promises);
  return rowsWithItems;
};

const fetchHomeCategoriesBooks = async (categoryId) => {
  const url = `${requests.home}/${categoryId}`;
  const { data } = await axios.get(url);
  if (data && data.items) {
    return data.items;
  }
  return [];
};

export default homeLoader;
export { fetchHomeCategoriesBooks, loadForHome, rows };
