import Moment from "react-moment";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayTwoTone";

const BookReleaseDate = ({ book }) => {
  return (
    <span className="mr-3 text-white text-sm">
      <CalendarTodayIcon fontSize="12" className="ml-2" />
      <Moment to={moment(book.scheduled)} local="ar" />
    </span>
  );
};

export default BookReleaseDate;
