class BreakpointsUtils {
  static getBreakpoints(isCats = false, count) {
    if (count === 1)
      return {
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
      };
    if (count === 2) {
      return {
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        550: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      };
    }
    if (count === 3) {
      return {
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        550: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      };
    }
    if (count === 4) {
      return {
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        550: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
      };
    }
    if (count === 5) {
      return {
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        550: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        1250: {
          slidesPerView: 5,
          spaceBetween: 1,
        },
      };
    }
    return {
      320: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
      550: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 5,
      },
      1250: {
        slidesPerView: 5,
        spaceBetween: 1,
      },
    };
  }

  static getSlidesPerGroup(isCats = false) {
    const screenWidth = window.innerWidth;

    const breakPoints = BreakpointsUtils.getBreakpoints(isCats);
    const breakPointsKeys = Object.keys(breakPoints);
    const closestBreakPoint = breakPointsKeys.reduce((prev, curr) => {
      return Math.abs(curr - screenWidth) < Math.abs(prev - screenWidth) ? curr : prev;
    });
    return breakPoints[closestBreakPoint]?.slidesPerView || 1;
  }
}

export default BreakpointsUtils;
